import RequestUtils from "utils/RequestUtils";
import DepartureRes from "types/res/DepartureRes";
import DepartureReq from "types/req/DepartureReq";

export default class DepartureApi {
  static async find(date: string) {
    return await RequestUtils.get(
      DepartureRes,
      `/driver/departure/find/${date}`
    );
  }

  static async create(req: DepartureReq) {
    return await RequestUtils.post(DepartureRes, `/driver/departure`, {
      ...req,
    });
  }
}
