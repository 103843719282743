import { DateTime, Settings } from "luxon";
Settings.defaultLocale = "JST";
Settings.defaultZone = "Asia/Tokyo";

export enum FORMAT_TYPE {
  DAY_LIST = "MM/dd",
  YEAR_DAY = "yyyy-MM-dd",
  YEAR_DAY_JP = "yyyy年MM月dd日",
  YEAR_MONTH = "yyyy-MM",
  YEAR_MONTH_JP = "yyyy年MM月",
  YEAR_DATE_TIME = "yyyy-MM-dd HH:mm",
  DATE_TIME = "MM/dd HH:mm",
  TIME = "HH:mm",
  HOUR_AMPM = "ha",
}

export default class DateTimeUtils {
  private constructor() {}

  public static toFormatAsLocalTimezone(d: Date, format: string) {
    return DateTime.fromJSDate(DateTime.fromJSDate(d).toJSDate()).toFormat(
      format
    );
  }
}
