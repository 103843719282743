import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as DailyReportIcon } from "assets/svg/daily-report.svg";
import { Box, Button, Divider, SvgIcon, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { fetchOneDailyReport } from "redux/actions/dailyReport";
const DailyReportShow = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const dailyReport = useAppSelector(
    (state) => state.dailyReport.entities.dailyReports[Number(id)]
  );
  useEffect(() => {
    if (!id) return;
    dispatch(fetchOneDailyReport({ id: +id }));
  }, [id]);

  return (
    <Box display={"flex"} flexDirection={"column"}>
      <Box
        display={"flex"}
        margin={2}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Box display="flex">
          <SvgIcon viewBox={"0 0 27 27"}>
            <DailyReportIcon />
          </SvgIcon>
          <Typography style={{ marginLeft: "10px" }}>日報詳細</Typography>
        </Box>
        <Box>
          <Button
            variant={"contained"}
            color={"info"}
            onClick={() => navigate("/dailyReport")}
          >
            一覧
          </Button>
        </Box>
      </Box>
      <Divider />
      <Box display={"flex"} flexDirection={"column"} marginTop={2} marginX={2}>
        <Box display={"flex"} marginTop={1}>
          <Typography style={{ width: "25%" }}>日付</Typography>
          <Typography>{dailyReport?.date}</Typography>
        </Box>
        <Box display={"flex"} marginTop={1}>
          <Typography style={{ width: "25%" }}>現場名</Typography>
          <Typography>
            {dailyReport?.deal?.supplier?.name} {dailyReport?.deal?.name}
          </Typography>
        </Box>
        <Box display={"flex"} marginTop={1}>
          <Typography style={{ width: "25%" }}>時間</Typography>
          <Typography>
            {dailyReport?.startTime} ~ {dailyReport?.endTime}
          </Typography>
        </Box>
        <Box display={"flex"} marginTop={1}>
          <Typography style={{ width: "25%" }}>距離</Typography>
          <Typography>{dailyReport?.distance || 0}km</Typography>
        </Box>
        <Box display={"flex"} marginTop={1}>
          <Typography style={{ width: "25%" }}>持出個数①</Typography>
          <Typography>{dailyReport?.bringOut1 || 0}個</Typography>
        </Box>
        <Box display={"flex"} marginTop={1}>
          <Typography style={{ width: "25%" }}>持出個数②</Typography>
          <Typography>{dailyReport?.bringOut2 || 0}個</Typography>
        </Box>
        <Box display={"flex"} marginTop={1}>
          <Typography style={{ width: "25%" }}>配完個数①</Typography>
          <Typography>{dailyReport?.deliveryComplete1 || 0}個</Typography>
        </Box>
        <Box display={"flex"} marginTop={1}>
          <Typography style={{ width: "25%" }}>配完個数②</Typography>
          <Typography>{dailyReport?.deliveryComplete2 || 0}個</Typography>
        </Box>
        <Box display={"flex"} marginTop={1}>
          <Typography style={{ width: "25%" }}>持出件数</Typography>
          <Typography>{dailyReport?.bringOutLocation || 0}件</Typography>
        </Box>
        <Box display={"flex"} marginTop={1}>
          <Typography style={{ width: "25%" }}>配完件数</Typography>
          <Typography>
            {dailyReport?.deliveryCompleteLocation || 0}件
          </Typography>
        </Box>
        <Box display={"flex"} marginTop={1}>
          <Typography style={{ width: "25%" }}>残業</Typography>
          <Typography>{dailyReport?.overTime || 0}時間</Typography>
        </Box>
        <Box display={"flex"} marginTop={1}>
          <Typography style={{ width: "25%" }}>経費</Typography>
          <Typography>{dailyReport?.expenses || 0}円</Typography>
        </Box>
        <Box display={"flex"} marginTop={1}>
          <Typography style={{ width: "25%" }}>備考</Typography>
          <Typography>{dailyReport?.remarks}</Typography>
        </Box>
      </Box>
    </Box>
  );
};
export default DailyReportShow;
