import { createAsyncThunk } from "@reduxjs/toolkit";
import AuthReq from "types/req/AuthReq";
import AuthApi from "api/AuthApi";

export const login = createAsyncThunk(
  "login",
  async ({ req }: { req: AuthReq }) => {
    return await AuthApi.login(req);
  }
);

export const logout = createAsyncThunk("logout", async () => {
  return await AuthApi.logout();
});
