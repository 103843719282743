import AuthRes from "types/res/AuthRes";
import { createSlice } from "@reduxjs/toolkit";
import { login, logout } from "redux/actions/auth";
import DriverRes from "types/res/DriverRes";

export type AuthState = AuthRes;
const initialState: AuthState = { accessToken: "", driver: {} as DriverRes };

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (_, { payload }) => ({
      ...payload,
    }));
    builder.addCase(logout.fulfilled, () => initialState);
  },
});

export default authSlice.reducer;
