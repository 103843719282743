import React, { useState } from "react";
import { Box, Button, Link, TextField } from "@mui/material";
import { useAppDispatch } from "redux/hooks";
import { useNavigate } from "react-router-dom";
import { login } from "redux/actions/auth";

const Login = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const onSubmit = () => {
    dispatch(login({ req: { email, password } }));
    navigate("/");
  };

  return (
    <Box padding={3} display="flex" justifyContent="center">
      <Box
        display={"flex"}
        flexDirection={"column"}
        padding={1}
        style={{ width: "70%" }}
      >
        <TextField
          type={"email"}
          name={"email"}
          value={email}
          label={"メールアドレス"}
          onChange={(event) => setEmail(event.target.value)}
          style={{ margin: "10px" }}
        />
        <TextField
          type={"password"}
          name={"password"}
          value={password}
          label={"パスワード"}
          onChange={(event) => setPassword(event.target.value)}
          style={{ margin: "10px" }}
        />
        <Button variant="contained" color="primary" onClick={onSubmit}>
          送信
        </Button>
        <Link
          href="/resetPassword/send"
          style={{ marginTop: "20px", textAlign: "center" }}
        >
          パスワードをお忘れの方・初回ログインの方はこちら
        </Link>
      </Box>
    </Box>
  );
};
export default Login;
