import SchemaUtils from "utils/SchemaUtils";
import DailyReportRes from "types/res/DailyReportRes";
import { createAsyncThunk } from "@reduxjs/toolkit";
import DailyReportApi from "api/DailyReportApi";
import { normalize } from "normalizr";
import DailyReportReq from "types/req/DailyReportReq";

const dailyReportSchema =
  SchemaUtils.createEntity<DailyReportRes>("dailyReports");
type schemaType = { dailyReports: { [key: number]: DailyReportRes } };

export const fetchDailyReport = createAsyncThunk(
  "fetchDailyReport",
  async ({ month }: { month: string }) => {
    const data = await DailyReportApi.find(month);
    return normalize<undefined, schemaType, number[]>(data, [
      dailyReportSchema,
    ]);
  }
);

export const fetchOneDailyReport = createAsyncThunk(
  "fetchOneDailyReport",
  async ({ id }: { id: number }) => {
    const data = await DailyReportApi.findOne(id);
    return normalize<undefined, schemaType, number>(data, dailyReportSchema);
  }
);

export const createDailyReport = createAsyncThunk(
  "createDailyReport",
  async ({ req }: { req: DailyReportReq }) => {
    const data = await DailyReportApi.create(req);
    return normalize<undefined, schemaType, number>(data, dailyReportSchema);
  }
);
