import React, { useEffect, useState } from "react";
import { Box, Button, Divider, SvgIcon, Typography } from "@mui/material";
import { ReactComponent as DetailIcon } from "assets/svg/detail.svg";
import { useNavigate, useParams } from "react-router-dom";
import DetailItemRes from "types/res/DetailItemRes";
import DetailItemApi from "api/DetailItemApi";

const DetailShow = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [detailItem, setDetailItem] = useState<DetailItemRes>(
    {} as DetailItemRes
  );

  useEffect(() => {
    if (!id) return;
    const apiCall = async () => {
      const res = await DetailItemApi.find(+id);
      setDetailItem(res);
    };
    apiCall();
  }, [id]);

  return (
    <Box display={"flex"} flexDirection={"column"}>
      <Box
        display={"flex"}
        margin={2}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Box display={"flex"}>
          <SvgIcon viewBox={"0 0 30 30"}>
            <DetailIcon />
          </SvgIcon>
          <Typography style={{ marginLeft: "10px" }}>明細詳細</Typography>
        </Box>
        <Box>
          <Button
            variant={"contained"}
            color={"info"}
            onClick={() => navigate("/detail")}
          >
            一覧
          </Button>
        </Box>
      </Box>
      <Divider />
      <Box display={"flex"} flexDirection={"column"} marginX={2}>
        <Box display={"flex"} marginTop={2}>
          <Typography>{detailItem?.date}</Typography>
          <Typography style={{ marginLeft: "10px" }}>
            {detailItem?.deal?.name}
          </Typography>
        </Box>
        <Box display={"flex"} marginTop={1}>
          <Typography>距離</Typography>
          <Typography style={{ marginLeft: "10px" }}>
            {detailItem.distance || detailItem?.dailyReport?.distance}
          </Typography>
        </Box>
        <Box display={"flex"} marginTop={1}>
          <Typography>持出個数①</Typography>
          <Typography style={{ marginLeft: "10px" }}>
            {detailItem.bringOut1 || detailItem?.dailyReport?.bringOut1}
          </Typography>
        </Box>
        <Box display={"flex"} marginTop={1}>
          <Typography>持出個数②</Typography>
          <Typography style={{ marginLeft: "10px" }}>
            {detailItem.bringOut2 || detailItem?.dailyReport?.bringOut2}
          </Typography>
        </Box>
        <Box display={"flex"} marginTop={1}>
          <Typography>配完個数①</Typography>
          <Typography style={{ marginLeft: "10px" }}>
            {detailItem.deliveryComplete1 ||
              detailItem?.dailyReport?.deliveryComplete1}
          </Typography>
        </Box>
        <Box display={"flex"} marginTop={1}>
          <Typography>配完個数②</Typography>
          <Typography style={{ marginLeft: "10px" }}>
            {detailItem.deliveryComplete2 ||
              detailItem?.dailyReport?.deliveryComplete2}
          </Typography>
        </Box>
        <Box display={"flex"} marginTop={1}>
          <Typography>持出件数</Typography>
          <Typography style={{ marginLeft: "10px" }}>
            {detailItem.bringOutLocation ||
              detailItem?.dailyReport?.bringOutLocation}
          </Typography>
        </Box>
        <Box display={"flex"} marginTop={1}>
          <Typography>配完件数</Typography>
          <Typography style={{ marginLeft: "10px" }}>
            {detailItem.deliveryCompleteLocation ||
              detailItem?.dailyReport?.deliveryCompleteLocation}
          </Typography>
        </Box>
        <Box display={"flex"} marginTop={1}>
          <Typography>残業</Typography>
          <Typography style={{ marginLeft: "10px" }}>
            {detailItem.overTime || detailItem?.dailyReport?.overTime}
          </Typography>
        </Box>
        <Box display={"flex"} marginTop={1}>
          <Typography>経費</Typography>
          <Typography style={{ marginLeft: "10px" }}>
            {detailItem.expenses || detailItem?.dailyReport?.expenses}
          </Typography>
        </Box>
        <Box display={"flex"} marginTop={1}>
          <Typography>合計</Typography>
          <Typography style={{ marginLeft: "10px" }}>
            {detailItem.total}円
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
export default DetailShow;
