import React from "react";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { Settings } from "luxon";
import { Route, Routes } from "react-router-dom";
import { FORMAT_TYPE } from "utils/DateTimeUtils";
import { useAppSelector } from "redux/hooks";
import ResetPassword from "pages/ResetPassword";
import ResetPasswordSent from "pages/ResetPasswordSent";
import Login from "pages/Login";
import Home from "pages/Home";
import DailyReport from "pages/DailyReport";
import DailyReportShow from "pages/DailyReport/show";
import Detail from "pages/Detail";
import DailyReportCreate from "pages/DailyReport/create";
import DetailShow from "pages/Detail/show";
Settings.defaultLocale = "ja";

const App = () => {
  const accessToken = useAppSelector((state) => state.auth.accessToken);
  return (
    <LocalizationProvider
      dateAdapter={AdapterLuxon}
      adapterLocale={"ja"}
      dateFormats={{ monthAndYear: FORMAT_TYPE.YEAR_MONTH }}
    >
      <Routes>
        {accessToken ? (
          <>
            <Route path={"dailyReport"}>
              <Route index Component={DailyReport} />
              <Route path={"create"} Component={DailyReportCreate} />
              <Route path={":id"} Component={DailyReportShow} />
            </Route>
            <Route path={"detail"}>
              <Route index Component={Detail} />
              <Route path={":id"} Component={DetailShow} />
            </Route>
            <Route path={"/"} Component={Home} />
          </>
        ) : (
          <>
            <Route path={"resetPassword"} Component={ResetPassword} />
            <Route path={"resetPassword/send"} Component={ResetPasswordSent} />
            <Route path={"*"} Component={Login} />
          </>
        )}
      </Routes>
    </LocalizationProvider>
  );
};
export default App;
