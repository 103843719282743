import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  SvgIcon,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ReactComponent as DailyReportIcon } from "assets/svg/daily-report.svg";
import DailyReportReq from "types/req/DailyReportReq";
import { DateTime } from "luxon";
import { FORMAT_TYPE } from "utils/DateTimeUtils";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { fetchDeal } from "redux/actions/deal";
import { createDailyReport } from "redux/actions/dailyReport";

const DailyReportCreate = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const driverId = useAppSelector((state) => state.auth.driver.id);
  const dealIds = useAppSelector((state) => state.deal.result);
  const dealEntities = useAppSelector((state) => state.deal.entities.deals);
  const deals = dealIds.map((id) => dealEntities[id]);
  const [formData, setFormData] = useState<any>({} as DailyReportReq);
  useEffect(() => {
    setFormData({
      date: DateTime.local().toFormat(FORMAT_TYPE.YEAR_DAY),
      driverId,
      isApprove: false,
    });
    dispatch(fetchDeal());
  }, []);

  const onChange = (key: string, value: any) => {
    setFormData((prev: any) => ({ ...prev, [key]: value }));
  };

  const typeComponent = (type?: string) => {
    switch (type) {
      case "car": {
        return null;
      }
      case "distance": {
        return (
          <Box marginTop={1}>
            <TextField
              type={"number"}
              label={"距離"}
              fullWidth
              value={formData?.distance || ""}
              onChange={(event) => {
                event.persist();
                onChange("distance", Number(event.target.value));
              }}
            />
          </Box>
        );
      }
      case "location": {
        return (
          <Box display={"flex"} flexDirection={"column"}>
            <Box marginTop={1}>
              <TextField
                type={"number"}
                label={"持出件数"}
                fullWidth
                value={formData?.bringOutLocation || ""}
                onChange={(event) => {
                  event.persist();
                  onChange("bringOutLocation", Number(event.target.value));
                }}
              />
            </Box>
            <Box marginTop={1}>
              <TextField
                type={"number"}
                label={"配完件数"}
                fullWidth
                value={formData?.deliveryCompleteLocation || ""}
                onChange={(event) => {
                  event.persist();
                  onChange(
                    "deliveryCompleteLocation",
                    Number(event.target.value)
                  );
                }}
              />
            </Box>
          </Box>
        );
      }
      case "piece": {
        return (
          <Box display={"flex"} flexDirection={"column"}>
            <Box marginTop={1}>
              <TextField
                type={"number"}
                label={"持出個数1"}
                fullWidth
                value={formData?.bringOut1 || ""}
                onChange={(event) => {
                  event.persist();
                  onChange("bringOut1", Number(event.target.value));
                }}
              />
            </Box>
            <Box marginTop={1}>
              <TextField
                type={"number"}
                label={"持出個数2"}
                fullWidth
                value={formData?.bringOut2 || ""}
                onChange={(event) => {
                  event.persist();
                  onChange("bringOut2", Number(event.target.value));
                }}
              />
            </Box>
            <Box marginTop={1}>
              <TextField
                type={"number"}
                label={"配完個数1"}
                fullWidth
                value={formData?.deliveryComplete1 || ""}
                onChange={(event) => {
                  event.persist();
                  onChange("deliveryComplete1", Number(event.target.value));
                }}
              />
            </Box>
            <Box marginTop={1}>
              <TextField
                type={"number"}
                label={"配完個数2"}
                fullWidth
                value={formData?.deliveryComplete2 || ""}
                onChange={(event) => {
                  event.persist();
                  onChange("deliveryComplete2", Number(event.target.value));
                }}
              />
            </Box>
          </Box>
        );
      }
      default: {
        return null;
      }
    }
  };

  const onClickSave = () => {
    if (
      !formData.date ||
      !formData.dealId ||
      !formData.startTime ||
      !formData.endTime
    ) {
      return alert("日付、現場、稼働開始、稼働終了は必須項目です。");
    }
    dispatch(
      createDailyReport({
        req: {
          ...formData,
        },
      })
    );
    navigate("/dailyReport");
  };
  return (
    <Box display="flex" flexDirection="column">
      <Box
        display={"flex"}
        margin={2}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Box display="flex">
          <SvgIcon viewBox={"0 0 27 27"}>
            <DailyReportIcon />
          </SvgIcon>
          <Typography style={{ marginLeft: "10px" }}>日報入力</Typography>
        </Box>
        <Box>
          <Button
            variant={"contained"}
            color={"info"}
            onClick={() => navigate("/dailyReport")}
          >
            一覧
          </Button>
        </Box>
      </Box>
      <Divider />
      <Box display={"flex"} marginTop={2}>
        <TextField
          type={"date"}
          label={"日付"}
          value={formData?.date || ""}
          onChange={(event) => {
            event.persist();
            onChange("date", event.target.value);
          }}
        />
      </Box>
      <Box display={"flex"} flexDirection={"column"} marginTop={2}>
        <Autocomplete
          options={deals || []}
          getOptionLabel={(option) =>
            `${option.name || ""} ${option.supplier?.siteName || ""}`
          }
          value={dealEntities?.[formData?.dealId] || ""}
          filterOptions={(filterOptions, state) =>
            filterOptions.filter((o) => o.name.includes(state.inputValue))
          }
          onChange={(event, value) => {
            event.persist();
            onChange("dealId", value?.id);
          }}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label={"現場名"} />
          )}
          noOptionsText="選択肢がありません"
        />
      </Box>
      <Box display={"flex"} flexDirection={"column"} marginTop={2}>
        <Box>
          <TextField
            type={"time"}
            label={"稼働開始"}
            fullWidth
            value={formData?.startTime || ""}
            onChange={(event) => {
              event.persist();
              onChange("startTime", event.target.value);
            }}
          />
        </Box>
        <Box marginTop={1}>
          <TextField
            type={"time"}
            label={"稼働終了"}
            fullWidth
            value={formData?.endTime || ""}
            onChange={(event) => {
              event.persist();
              onChange("endTime", event.target.value);
            }}
          />
        </Box>
        {typeComponent(dealEntities?.[formData?.dealId]?.rewardType)}
        <Box marginTop={1}>
          <TextField
            type={"number"}
            label={"残業"}
            fullWidth
            value={formData?.overTime || ""}
            onChange={(event) => {
              event.persist();
              onChange("overTime", event.target.value);
            }}
          />
        </Box>
        <Box marginTop={1}>
          <TextField
            type={"number"}
            label={"経費"}
            fullWidth
            value={formData?.expenses || ""}
            onChange={(event) => {
              event.persist();
              onChange("expenses", event.target.value);
            }}
          />
        </Box>
        <Box marginTop={1}>
          <TextareaAutosize
            minRows={10}
            style={{ width: "100%" }}
            value={formData.remarks || ""}
            onChange={(event) => {
              event.persist();
              onChange("remarks", event.target.value);
            }}
          />
        </Box>
        <Box display={"flex"} margin={2} justifyContent={"center"}>
          <Button
            variant={"contained"}
            color={"primary"}
            fullWidth
            onClick={() => onClickSave()}
          >
            送信
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default DailyReportCreate;
