import BaseRes from "types/res/BaseRes";
import { Expose, Type } from "class-transformer";
import DriverRes from "types/res/DriverRes";
import AccountRes from "types/res/AccountRes";

export default class DepartureRes extends BaseRes {
  @Type(() => AccountRes)
  @Expose()
  readonly account!: AccountRes;
  @Expose() readonly driverId!: number;
  @Type(() => DriverRes)
  @Expose()
  readonly driver!: DriverRes;
  @Expose() readonly date!: string;
  @Expose() readonly time!: string;
}
