import DailyReportRes from "types/res/DailyReportRes";
import { createSlice } from "@reduxjs/toolkit";
import {
  createDailyReport,
  fetchDailyReport,
  fetchOneDailyReport,
} from "redux/actions/dailyReport";

export type DailyReportState = {
  entities: { dailyReports: { [key: number]: DailyReportRes } };
  result: number[];
};
const initialState: DailyReportState = {
  entities: { dailyReports: {} },
  result: [],
};

const dailyReportSlice = createSlice({
  name: "dailyReport",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDailyReport.fulfilled, (_, { payload }) => ({
      entities: { dailyReports: { ...payload.entities.dailyReports } },
      result: payload.result,
    }));
    builder.addCase(fetchOneDailyReport.fulfilled, (_, { payload }) => ({
      entities: { dailyReports: { ...payload.entities.dailyReports } },
      result: [payload.result],
    }));
    builder.addCase(createDailyReport.fulfilled, (state, { payload }) => ({
      entities: {
        dailyReports: {
          ...state.entities.dailyReports,
          ...payload.entities.dailyReports,
        },
      },
      result: [...state.result, payload.result],
    }));
  },
});

export default dailyReportSlice.reducer;
