import DetailRes from "types/res/DetailRes";
import { createSlice } from "@reduxjs/toolkit";
import { fetchDetail } from "redux/actions/detail";

export type DetailState = DetailRes;
const initialState: DetailState = {} as DetailRes;

const detailSlice = createSlice({
  name: "detail",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDetail.fulfilled, (_, { payload }) => payload);
  },
});

export default detailSlice.reducer;
