import BaseRes from "types/res/BaseRes";
import { Expose, Type } from "class-transformer";

export default class SupplierRes extends BaseRes {
  @Expose() name!: string;
  @Expose() kana!: string;
  @Expose() siteName!: string;
  @Expose() siteKana!: string;
  @Expose() invoiceNumber?: string;
  @Expose() tel?: string;
  @Expose() email?: string;
  @Expose() postCode?: string;
  @Expose() address?: string;
  @Type(() => Date)
  @Expose()
  registeredAt?: Date;
  @Expose() remarks?: string;
}
