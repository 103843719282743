export default class EnvironmentUtils {
  private constructor() {}

  public static getApiUrl() {
    return process.env.REACT_APP_API_BASE || "";
  }

  public static getSentryDsn() {
    return process.env.REACT_APP_SENTRY_DSN || "";
  }

  public static getSentryEnvironment() {
    return process.env.REACT_APP_SENTRY_ENVIRONMENT || "";
  }

  public static getSentryRelease() {
    return process.env.REACT_APP_SENTRY_RELEASE || "";
  }

  public static getSentryDebug() {
    return !!process.env.REACT_APP_SENTRY_DEBUG;
  }
}
