import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import { ReactComponent as DailyReportIcon } from "assets/svg/daily-report.svg";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import { FORMAT_TYPE } from "utils/DateTimeUtils";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { fetchDailyReport } from "redux/actions/dailyReport";
import DailyReportRes from "types/res/DailyReportRes";

const DailyReport = () => {
  const dispatch = useAppDispatch();
  const [month, setMonth] = useState(
    DateTime.local().toFormat(FORMAT_TYPE.YEAR_MONTH)
  );
  const dailyReportIds = useAppSelector((state) => state.dailyReport.result);
  const dailyReportEntities = useAppSelector(
    (state) => state.dailyReport.entities.dailyReports
  );
  const dailyReports = dailyReportIds.map((id) => dailyReportEntities[id]);

  useEffect(() => {
    if (!month) return;
    dispatch(fetchDailyReport({ month }));
  }, [month]);

  return (
    <Box display={"flex"} flexDirection={"column"}>
      <Box display={"flex"} marginLeft={2} marginTop={2} alignItems={"center"}>
        <SvgIcon viewBox={"0 0 27 27"}>
          <DailyReportIcon />
        </SvgIcon>
        <Typography style={{ marginLeft: "10px" }}>日報一覧</Typography>
        <TextField
          type={"month"}
          value={month}
          size={"small"}
          style={{ marginLeft: "10px" }}
          onChange={(event) => {
            event.persist();
            setMonth(event.target.value);
          }}
        />
      </Box>
      {dailyReports.map((dailyReport) => (
        <Item dailyReport={dailyReport} />
      ))}
    </Box>
  );
};

type DailyReportItemProps = {
  dailyReport: DailyReportRes;
};
const Item = ({ dailyReport }: DailyReportItemProps) => {
  const navigate = useNavigate();
  return (
    <Box display={"flex"} flexDirection={"column"} marginTop={1}>
      <Divider />
      <Box display={"flex"} justifyContent={"space-around"} marginTop={1}>
        <Box>
          <Typography>{dailyReport.date}</Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"}>
          <Typography>{dailyReport?.deal?.supplier?.name}</Typography>
          <Typography>{dailyReport?.deal?.name}</Typography>
        </Box>
        <Box>
          <Button
            variant={"contained"}
            color={"secondary"}
            onClick={() => navigate(`/dailyReport/${dailyReport.id}`)}
          >
            詳細
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
export default DailyReport;
