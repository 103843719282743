import { Expose, Type } from "class-transformer";
import BaseRes from "types/res/BaseRes";
import DriverRes from "types/res/DriverRes";
import AccountRes from "types/res/AccountRes";
import DealRes from "types/res/DealRes";

export default class DailyReportRes extends BaseRes {
  @Expose() accountId!: number;
  @Type(() => AccountRes)
  @Expose()
  account!: AccountRes;
  @Expose() driverId!: number;
  @Type(() => DriverRes)
  @Expose()
  driver!: DriverRes;

  @Expose() dealId!: number;
  @Type(() => DealRes)
  @Expose()
  deal!: DealRes;

  @Expose()
  isApprove!: boolean;

  @Expose()
  date!: string;

  @Expose()
  startTime!: string;

  @Expose()
  endTime?: string;

  @Expose()
  distance?: number;

  @Expose()
  bringOut1?: number;

  @Expose()
  bringOut2?: number;

  @Expose()
  deliveryComplete1?: number;

  @Expose()
  deliveryComplete2?: number;

  @Expose()
  bringOutLocation?: number;

  @Expose()
  deliveryCompleteLocation?: number;

  @Type(() => Number)
  @Expose()
  overTime?: number;

  @Expose()
  expenses?: number;

  @Expose()
  remarks?: string;
}
