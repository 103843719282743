import DealRes from "types/res/DealRes";
import { createSlice } from "@reduxjs/toolkit";
import { fetchDeal } from "redux/actions/deal";

export type DealState = {
  entities: { deals: { [key: number]: DealRes } };
  result: number[];
};
const initialState: DealState = {
  entities: { deals: {} },
  result: [],
};

const dealSlice = createSlice({
  name: "deal",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDeal.fulfilled, (_, { payload }) => ({
      entities: { deals: { ...payload.entities.deals } },
      result: payload.result,
    }));
  },
});

export default dealSlice.reducer;
