import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  Container,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import DriverApi from "api/DriverApi";
const ResetPassword = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const queryString = new URLSearchParams(useLocation().search);
  const resetToken = queryString.get("token");
  const [driverId, setDriverId] = useState<number>();
  const [isSent, setIsSent] = useState(false);

  const onChangePassword = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPassword(event.target.value);
  };

  const onClickPostButton = async () => {
    if (!password || !driverId) return;

    setLoading(true);
    await DriverApi.resetPassword(driverId, password);
    setLoading(false);
    setIsSent(true);
  };
  useEffect(() => {
    const getDriver = async () => {
      try {
        const result = await DriverApi.findByResetToken(resetToken || "");
        setDriverId(result.id);
      } catch {
        navigate("/resetPassword/send");
      }
    };
    getDriver();
  }, []);

  return (
    <Container style={{ marginTop: "40px" }}>
      <Typography>新しいパスワードを入力してください。</Typography>
      <TextField
        label="パスワード"
        style={{ marginTop: "10px" }}
        required
        type="password"
        autoComplete="current-password"
        variant="outlined"
        fullWidth
        onChange={onChangePassword}
      />
      <Button
        variant="contained"
        style={{ marginTop: "10px" }}
        color="primary"
        fullWidth
        disabled={loading || isSent}
        onClick={onClickPostButton}
      >
        {loading ? <CircularProgress color="inherit" size={24} /> : "送信"}
      </Button>
      {isSent && (
        <Alert severity="success" style={{ marginTop: "10px" }}>
          <AlertTitle>パスワードを再設定しました。</AlertTitle>
          <Link href="/login">ログインページ</Link>
          へ遷移してログインしてください。
        </Alert>
      )}
    </Container>
  );
};
export default ResetPassword;
