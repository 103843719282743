import { createAsyncThunk } from "@reduxjs/toolkit";
import DealApi from "api/DealApi";
import { normalize } from "normalizr";
import SchemaUtils from "utils/SchemaUtils";
import DealRes from "types/res/DealRes";

const dealSchema = SchemaUtils.createEntity<DealRes>("deals");
type schemaType = { deals: { [key: number]: DealRes } };

export const fetchDeal = createAsyncThunk("fetchDeal", async () => {
  const data = await DealApi.find();
  return normalize<undefined, schemaType, number[]>(data, [dealSchema]);
});
