import React from "react";
import styled from "styled-components";
import Header from "components/Header";

type Props = {
  children: React.ReactElement;
};

const Container = styled.div`
  max-width: 768px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: 100vh;
`;
const Layout = ({ children }: Props) => {
  return (
    <>
      <Container>
        <Header />
        {children}
      </Container>
    </>
  );
};
export default Layout;
