import React, { useEffect, useState } from "react";
import { Box, Button, SvgIcon, Typography } from "@mui/material";
import { ReactComponent as DailyReportIcon } from "assets/svg/daily-report.svg";
import { ReactComponent as DetailIcon } from "assets/svg/detail.svg";
import { ReactComponent as EditDailyReportIcon } from "assets/svg/edit-daily-report.svg";
import { useNavigate } from "react-router-dom";
import DepartureApi from "api/DepartureApi";
import { useAppSelector } from "redux/hooks";
import { DateTime } from "luxon";
import { FORMAT_TYPE } from "utils/DateTimeUtils";
import DepartureRes from "types/res/DepartureRes";
const Home = () => {
  const driverId = useAppSelector((state) => state.auth.driver.id);
  const navigate = useNavigate();
  const [departured, setDepartured] = useState<DepartureRes>();
  const onClick = async () => {
    const dateTime = DateTime.local();
    const departure = await DepartureApi.create({
      driverId,
      date: dateTime.toFormat(FORMAT_TYPE.YEAR_DAY),
      time: dateTime.toFormat(FORMAT_TYPE.TIME),
    });
    setDepartured(departure);
  };

  useEffect(() => {
    const fetchDeparture = async () => {
      const departure = await DepartureApi.find(
        DateTime.local().toFormat(FORMAT_TYPE.YEAR_DAY)
      );
      setDepartured(departure?.id ? departure : undefined);
    };
    fetchDeparture();
  }, []);
  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{ backgroundColor: "#EEECEC", height: "100vh" }}
    >
      <Box display="flex" justifyContent={"space-around"} paddingY={6}>
        <Box
          width={"40%"}
          paddingY={2}
          display={"flex"}
          justifyContent={"center"}
          style={{ backgroundColor: "white", borderRadius: "10px" }}
          onClick={() => navigate("/dailyReport")}
        >
          <SvgIcon viewBox={"0 0 27 27"}>
            <DailyReportIcon />
          </SvgIcon>
          <Typography style={{ marginLeft: "10px" }}>日報一覧</Typography>
        </Box>
        <Box
          width={"40%"}
          paddingY={2}
          display={"flex"}
          justifyContent={"center"}
          style={{ backgroundColor: "white", borderRadius: "10px" }}
          onClick={() => navigate("/detail")}
        >
          <SvgIcon viewBox={"0 0 30 30"}>
            <DetailIcon />
          </SvgIcon>
          <Typography style={{ marginLeft: "10px" }}>明細一覧</Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent={"center"} marginY={6}>
        <Box
          width={"90%"}
          paddingX={6}
          paddingY={2}
          display={"flex"}
          justifyContent={"center"}
          style={{ backgroundColor: "white", borderRadius: "10px" }}
          onClick={() => navigate("/dailyReport/create")}
        >
          <SvgIcon viewBox={"0 0 21  21 "}>
            <EditDailyReportIcon />
          </SvgIcon>
          <Typography style={{ marginLeft: "10px" }}>日報入力</Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent={"center"} marginY={6}>
        <Button
          variant={"contained"}
          color={"secondary"}
          style={{ padding: "10px", width: "90%" }}
          onClick={onClick}
          disabled={!!departured}
        >
          出発連絡
        </Button>
      </Box>
    </Box>
  );
};
export default Home;
