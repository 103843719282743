import BaseRes from "types/res/BaseRes";
import { Expose, Type } from "class-transformer";
import SupplierRes from "types/res/SupplierRes";
import { RewardType } from "types/enum/RewardType";

export default class DealRes extends BaseRes {
  @Expose() readonly supplierId!: number;
  @Type(() => SupplierRes)
  @Expose()
  readonly supplier!: SupplierRes;
  @Expose() readonly name!: string;
  @Expose() readonly rewardType!: RewardType;
  @Expose() readonly remarks?: string;
  @Expose() readonly companyProfit?: number;
  @Expose() readonly driverSalary?: number;
  @Expose() readonly billingAmount?: number;
  @Expose() readonly overtimeMoney?: number;
  @Expose() readonly distanceMoney?: number;
}
