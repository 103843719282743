import React from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { AppBar, Box, Button, Link, SvgIcon, Toolbar } from "@mui/material";
import styled from "styled-components";
import { logout } from "redux/actions/auth";
import LogoIcon from "assets/svg/logo.svg";

const Logo = styled(Link)`
  display: flex;
  justify-self: center;
  img {
    height: 28px;
  }
`;
const Header = () => {
  const isLoggedIn = useAppSelector((state) => Boolean(state.auth.accessToken));
  const dispatch = useAppDispatch();

  const onClickLogoutButton = () => {
    dispatch(logout());
  };
  return (
    <AppBar
      position="sticky"
      elevation={0}
      style={{ backgroundColor: "white" }}
    >
      <Toolbar>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width={"100%"}
        >
          <Logo href={"/"}>
            <img src={LogoIcon} />
          </Logo>
          {isLoggedIn && (
            <Button variant={"contained"} onClick={() => onClickLogoutButton()}>
              ログアウト
            </Button>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
