import RequestUtils from "utils/RequestUtils";
import DetailRes from "types/res/DetailRes";

export default class DetailApi {
  static async find(month: string) {
    return await RequestUtils.get(DetailRes, `/driver/detail/find/${month}`);
  }

  static async isRead(id: number) {
    return await RequestUtils.putVoid(`/driver/detail/isRead/${id}`, {});
  }
}
