import { Expose, Type } from "class-transformer";

export default class BaseRes {
  @Expose() readonly id!: number;
  @Type(() => Date)
  @Expose()
  readonly createdAt!: Date;
  @Type(() => Date)
  @Expose()
  readonly updatedAt!: Date;
}
