import RequestUtils from "utils/RequestUtils";
import DetailItemRes from "types/res/DetailItemRes";

export default class DetailItemApi {
  static async find(id: number) {
    return await RequestUtils.get(
      DetailItemRes,
      `/driver/detailItem/find/${id}`
    );
  }
}
