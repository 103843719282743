import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import { ReactComponent as DetailIcon } from "assets/svg/detail.svg";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import { FORMAT_TYPE } from "utils/DateTimeUtils";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { fetchDetail } from "redux/actions/detail";
import DetailItemRes from "types/res/DetailItemRes";
import DetailApi from "api/DetailApi";
import { isRejected } from "@reduxjs/toolkit";

const Detail = () => {
  const dispatch = useAppDispatch();
  const detail = useAppSelector((state) => state.detail);
  const [month, setMonth] = useState(
    DateTime.local().toFormat(FORMAT_TYPE.YEAR_MONTH)
  );

  useEffect(() => {
    if (!month) return;
    dispatch(fetchDetail({ month }));
  }, [month]);

  useEffect(() => {
    const apiCall = async () => {
      if (!detail.id) return;
      await DetailApi.isRead(detail.id);
    };
    apiCall();
  }, [detail]);

  return (
    <Box display={"flex"} flexDirection={"column"}>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        marginX={2}
        marginTop={2}
      >
        <Box display={"flex"} alignItems={"center"}>
          <SvgIcon viewBox={"0 0 30 30"}>
            <DetailIcon />
          </SvgIcon>
          <Typography style={{ marginLeft: "10px" }}>明細一覧</Typography>
          <TextField
            type={"month"}
            value={month}
            size={"small"}
            style={{ marginLeft: "10px" }}
            onChange={(event) => {
              event.persist();
              setMonth(event.target.value);
            }}
          />
        </Box>
        <Typography style={{ marginLeft: "10px" }}>
          月間合計
          {detail.total ||
            detail?.detailItems?.reduce(
              (sum, detailItem) => sum + (detailItem.total || 0),
              0
            ) ||
            0}
          円
        </Typography>
      </Box>
      {detail.detailItems?.map((detailItem) => (
        <Item
          detailItem={detailItem}
          isNotify={detail.isNotify}
          isRead={detail.isRead}
        />
      ))}
    </Box>
  );
};

type DetailItemProps = {
  isNotify: boolean;
  isRead: boolean;
  detailItem: DetailItemRes;
};
const Item = ({ isNotify, isRead, detailItem }: DetailItemProps) => {
  const navigate = useNavigate();
  return (
    <Box display={"flex"} flexDirection={"column"} marginTop={1}>
      <Divider />
      <Box display={"flex"} justifyContent={"space-around"} marginTop={1}>
        {isNotify && !isRead ? (
          <Box
            style={{
              marginTop: "10px",
              width: "20px",
              height: "20px",
              backgroundColor: "red",
              borderRadius: "50%",
            }}
          ></Box>
        ) : null}
        <Box>
          <Typography>{detailItem.date}</Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"}>
          <Box display={"flex"}>
            <Typography>{detailItem?.deal?.supplier?.name || ""}</Typography>
            <Typography style={{ marginLeft: "10px" }}>
              {detailItem?.deal?.name || ""}
            </Typography>
          </Box>
          <Typography>金額{detailItem?.total || 0}円</Typography>
        </Box>
        <Box>
          <Button
            variant={"contained"}
            color={"secondary"}
            onClick={() => navigate(`/detail/${detailItem.id}`)}
          >
            詳細
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
export default Detail;
