import RequestUtils from "utils/RequestUtils";
import DriverRes from "types/res/DriverRes";

export default class DriverApi {
  static async findByResetToken(resetToken: string) {
    return await RequestUtils.get(
      DriverRes,
      `/driver/driver/findByResetToken?token=${resetToken}`
    );
  }

  static async resetPassword(driverId: number, password: string) {
    return await RequestUtils.post(DriverRes, `/driver/driver/passwordReset`, {
      driverId,
      password,
    });
  }

  static async resetPasswordSendEmail(email: string) {
    return await RequestUtils.postVoid(
      `/driver/driver/passwordReset/sendEmail`,
      {
        email,
      }
    );
  }
}
