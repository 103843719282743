import BaseRes from "types/res/BaseRes";
import { Expose, Type } from "class-transformer";
import DetailRes from "types/res/DetailRes";
import DealRes from "types/res/DealRes";
import DailyReportRes from "types/res/DailyReportRes";

export default class DetailItemRes extends BaseRes {
  @Expose() readonly detailId!: number;
  @Type(() => DetailRes)
  @Expose()
  readonly detail!: DetailRes;
  @Expose() readonly dealId!: number;
  @Type(() => DealRes)
  @Expose()
  readonly deal!: DealRes;
  @Expose() readonly dailyReportId?: number;
  @Type(() => DailyReportRes)
  @Expose()
  readonly dailyReport?: DailyReportRes;
  @Expose() readonly date!: string;
  @Expose() readonly distance?: number;
  @Expose() readonly bringOut1?: number;
  @Expose() readonly bringOut2?: number;
  @Expose() readonly deliveryComplete1?: number;
  @Expose() readonly deliveryComplete2?: number;
  @Expose() readonly bringOutLocation?: number;
  @Expose() readonly deliveryCompleteLocation?: number;
  @Expose() readonly overTime?: number;
  @Expose() readonly expenses?: number;
  @Expose() readonly customize1?: number;
  @Expose() readonly customize2?: number;
  @Expose() readonly total!: number;
}
