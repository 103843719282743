import RequestUtils from "utils/RequestUtils";
import DailyReportRes from "types/res/DailyReportRes";
import DailyReportReq from "types/req/DailyReportReq";

export default class DailyReportApi {
  static async find(month: string) {
    return await RequestUtils.getArray(
      DailyReportRes,
      `/driver/dailyReport/find/${month}`
    );
  }
  static async findOne(id: number) {
    return await RequestUtils.get(
      DailyReportRes,
      `/driver/dailyReport/findOne/${id}`
    );
  }

  static async create(req: DailyReportReq) {
    return await RequestUtils.post(DailyReportRes, `/driver/dailyReport`, {
      ...req,
    });
  }
}
