import RequestUtils from "utils/RequestUtils";
import AuthReq from "types/req/AuthReq";
import AuthRes from "types/res/AuthRes";

export default class AuthApi {
  static async login(data: AuthReq) {
    return await RequestUtils.post(AuthRes, `/driver/auth/login`, data);
  }

  static async logout() {
    return await RequestUtils.postVoid(`/driver/auth/logout`, undefined);
  }
}
