import { store } from "index";
import { combineReducers } from "@reduxjs/toolkit";
import auth from "redux/reducer/auth";
import modals from "redux/reducer/modals";
import dailyReport from "redux/reducer/dailyReport";
import detail from "redux/reducer/detail";
import deal from "./reducer/deal";
export const rootReducer = combineReducers({
  auth,
  dailyReport,
  deal,
  detail,
  modals,
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
